import request from "@/utils/request";
import requestForm from "@/utils/requestForm";

/* 创建打印信息  返回noStr
  params = {
    *ids（内容 1,2,3,4,5）,
    show_answer (不传默认0不显示)
  }
*/
export function apiCreatePreview(params) {
  return requestForm.post("/h5/prints/createPreview", params);
}

/* 打印预览  返回题目数据
  params = {
    *noStr
  }
*/
export function apiGetPreviewData(params) {
  return request.get("/h5/prints/previewData", { params });
}

/* 打印预览   返回PDF
  params = {
    *noStr
    *urlPath(如：prints/preview/index)
  }
*/
export function apiCreateFile(params) {
  return requestForm.post("/h5/prints/createFile", params);
}

/* 打印
  params = {
    *noStr
  }
*/
export function apiDo(params) {
  return requestForm.post("/h5/prints/do", params);
}
