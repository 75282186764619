<template>
  <div>
    <div v-for="item in questions.questions" :key="item.id">
      <div class="textCase" v-if="item.way == 1">
        <div class="question">{{ item.question }}</div>
        <div class="options" v-for="opItem in item.question_option" :key="opItem">
          {{ opItem.text }}
        </div>
      </div>
      <div class="picCase" v-if="item.way == 2">
        <van-image
          width="100%"
          height="100%"
          :src="prefix(item.question)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"
  import { prefix } from '@/common/js/utils'
  import { apiGetPreviewData } from "@/service/prints.js"
  
  const route = useRoute()
  
  let questions = ref([])
  
  
  const getInfo = async() =>{
    let params={
      noStr:route.query.noStr
    }
    let res = await apiGetPreviewData(params)
    questions.value = res.data
  }
  getInfo()
</script>

<style lang="less" scoped>
</style>